import {
  makeRoute
} from "./chunk-ZGNZ6OJY.mjs";

// src/utm.ts
var UTM_CAMPAIGN = {
  matchastock: "matchastock",
  matchastock_list: "matchastock-list",
  matchastock_privacy: "matchastock-privacy",
  matchastock_sign_up: "matchastock-sign-up",
  matchastock_support: "matchastock-support",
  matchastock_terms: "matchastock-terms",
  matchastock_unsubscribe: "matchastock-unsubscribe"
};
var UTM_SOURCE = {
  resend: "resend",
  matchastock: "matchastock"
};
var UTM_MEDIUM = {
  email: "email",
  web: "web"
};
function buildUtmAbsoluteHref(href, utmParams) {
  const url = new URL(href);
  url.searchParams.set("utm_campaign", utmParams.campaign);
  url.searchParams.set("utm_medium", utmParams.medium);
  url.searchParams.set("utm_source", utmParams.source);
  if (utmParams.term) {
    url.searchParams.set("utm_term", utmParams.term);
  }
  if (utmParams.content) {
    url.searchParams.set("utm_content", utmParams.content);
  }
  return url.toString();
}
function buildUtmRelativeHref(path, utmParams) {
  return makeRoute({
    path,
    query: {
      utm_campaign: utmParams.campaign,
      utm_medium: utmParams.medium,
      utm_source: utmParams.source,
      utm_term: utmParams.term,
      utm_content: utmParams.content
    }
  });
}
export {
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
  buildUtmAbsoluteHref,
  buildUtmRelativeHref
};
